import React from "react";
import { Route, Switch } from 'react-router-dom';
import LoaderWrapper from "../components/LoaderWrapper";
import ProtectedRoute from "../components/ProtectedRoute";
import PointsManagementApprovalGrid from "../pages/PointsManagementApproval/PointsManagementApprovalGrid";
import Subwallet from "../services/subwallet";

const SignIn = React.lazy(() => import("../pages/SignIn"));
const SignOut = React.lazy(() => import("../pages/SignOut"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
const MyAccount = React.lazy(() => import("../pages/MyAccount"));
const AdminAccounts = React.lazy(() => import("../pages/AdminAccounts"));
const SecurityGroups = React.lazy(() => import("../pages/SecurityGroups"));
const MasterMerchant = React.lazy(() => import("../pages/MasterMerchant"));
const PaymentMerchant = React.lazy(() => import("../pages/PaymentMerchant"));
const ConsumerManagement = React.lazy(() => import("../pages/ConsumerManagement"));
const LoyaltyIssuanceTransaction = React.lazy(() => import("../pages/LoyaltyIssuanceTransaction"));
const LoyaltyTransaction = React.lazy(() => import("../pages/LoyaltyTransaction"));
const PaymentTransaction = React.lazy(() => import("../pages/PaymentTransaction"));
const PaymentInvoice = React.lazy(() => import("../pages/PaymentInvoice"));
const AuditTrails = React.lazy(() => import("../pages/AuditTrails"));
const SubwalletInvoice = React.lazy(() => import("../pages/SubwalletInvoice"));
const SubwalletAccounts = React.lazy(() => import("../pages/SubwalletAccounts"));
const SubwalletAccountDetails = React.lazy(() => import("../pages/SubwalletAccountDetails"));
const SubwalletAccountHistory = React.lazy(() => import("../pages/SubwalletAccountHistory"));
const SubwalletTransactions = React.lazy(() => import("../pages/SubwalletTransactions"));
const AccountThemes = React.lazy(() => import("../pages/AccountThemes"));
const MerchantThemes = React.lazy(() => import("../pages/MerchantThemes"));
const SubwalletPoints = React.lazy(() => import("../pages/SubwalletPoints"));
const PointsManagement = React.lazy(() => import("../pages/PointsManagement"));
const PointsManagementApproval = React.lazy(() => import("../pages/PointsManagementApproval"));
const ReportRequestCreate = React.lazy(() => import("../pages/ReportRequestCreate"));
const ReportRequestListing = React.lazy(() => import("../pages/ReportRequestListing"));

const Routes = {
  SIGNIN: {
    path: '/signin',
    exact: true,
    public: true,
    component: SignIn
  },
  SIGNOUT: {
    path: '/signout',
    exact: true,
    public: true,
    component: SignOut
  },
  RESET_PASSWORD: {
    path: '/reset-password',
    public: true,
    exact: true,
    component: ResetPassword
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    exact: true,
    public: true,
    component: ForgotPassword
  },
  ADMIN_ACCOUNTS: {
    path: '/w/admin-user',
    exact: true,
    component: AdminAccounts
  },
  SECURITY_GROUPS: {
    path: '/w/admin-security-group',
    exact: true,
    component: SecurityGroups
  },
  MASTER_MERCHANT: {
    path: '/w/master-merchant',
    exact: true,
    component: MasterMerchant
  },
  PAYMENT_MERCHANT: {
    path: '/w/payment-merchant',
    exact: true,
    component: PaymentMerchant
  },
  CONSUMER_MANAGEMENT: {
    path: '/w/consumer-management',
    exact: true,
    component: ConsumerManagement
  },
  LOYALTY_ISSUANCE_TRANSACTION: {
    path: '/w/loyalty-issuance-transaction',
    exact: true,
    component: LoyaltyIssuanceTransaction
  },
  LOYALTY_TRANSACTION: {
    path: '/w/loyalty-transaction',
    exact: true,
    component: LoyaltyTransaction
  },
  PAYMENT_TRANSCTION: {
    path: '/w/payment-transaction',
    exact: true,
    component: PaymentTransaction
  },
  PAYMENT_INVOICE: {
    path: '/w/payment-invoice',
    exact: true,
    component: PaymentInvoice
  },
  AUDIT_TRAILS: {
    path: '/w/audit-trails',
    exact: true,
    component: AuditTrails
  },
  SUBWALLET_INVOICE: {
    path: '/w/subwallet-invoice',
    exact: true,
    component: SubwalletInvoice
  },
  SUBWALLET_ACCOUNTS: {
    path: '/w/subwallet-accounts',
    exact: true,
    component: SubwalletAccounts
  },
  SUBWALLET_ACCOUNT_DETAILS: {
    path: '/w/subwallet-account-details',
    exact: false,
    component: SubwalletAccountDetails
  },
  SUBWALLET_ACCOUNT_HISTORY: {
    path: '/w/subwallet-account-history',
    exact: false,
    component: SubwalletAccountHistory
  },
  SUBWALLET_TRANSACTIONS: {
    path: '/w/subwallet-transactions',
    exact: true,
    component: SubwalletTransactions
  },
  ACCOUNT_THEMES:{
    path:'/w/account-themes',
    exact: true,
    component: AccountThemes
  },
  MERCHANT_THEMES:{
    path:'/w/merchant-themes',
    exact: true,
    component: MerchantThemes
  },
  SUBWALLET_POINTS: {
    path: '/w/subwallet-points',
    exact: true,
    component: SubwalletPoints
  },
  POINTS_MANAGEMENT: {
    path: '/w/points-management',
    exact: true,
    component: PointsManagement
  },
  POINTS_MANAGEMENT_APPROVAL: {
    path: '/w/points-management-approval',
    exact: true,
    component: PointsManagementApproval
  },
  CREATE_REPORT_REQUEST: {
    path: '/w/create-report',
    exact: true,
    component: ReportRequestCreate
  },
  REPORT_REQUESTS: {
    path: '/w/report-requests',
    exact: true,
    component: ReportRequestListing
  },
  MY_ACCOUNT: { /** Home should always be last */
  path: '/',
  exact: false,
    component: MyAccount
  }
}

const RoutesAsList = Object.entries(Routes).map(([key, value]) => ({ v: value }));

const loading = () => <LoaderWrapper isShown={true} />;

function SwitchRoutes() {
  return (
    <React.Suspense fallback={loading()}>
      <Switch>
        {RoutesAsList.map((route, key) => (
          route.v.public === true ?
            <Route key={key} exact={route.v.exact ? false : true} path={route.v.path} component={route.v.component} /> :
            <ProtectedRoute key={key} exact={route.v.exact ? false : true} path={route.v.path} component={route.v.component} />
        ))}
      </Switch>
    </React.Suspense>
  )
};

export {
  Routes,
  SwitchRoutes
};

import SysConst from "./SysConst";

const ApiAddress = {
    // Common
    MOBILE_COUNTRY_CODE_LIST : SysConst.API_BASE_URL + '/v1/deals/int/common/service/mobile-country-code',

    //Auth
    AUTH_TOKEN : SysConst.API_BASE_URL + '/auth/realms/merchant-portal/protocol/openid-connect/token',
    AUTH_TOKEN_REFRESH : SysConst.API_BASE_URL + '/auth/realms/merchant-portal/protocol/openid-connect/token',
    AUTH_LOGOUT : SysConst.API_BASE_URL + '/auth/realms/merchant-portal/protocol/openid-connect/logout',
    USER_INFO : SysConst.API_BASE_URL + '/auth/realms/merchant-portal/protocol/openid-connect/userinfo',
    AUTH_TOKEN_RESET_PASSWORD : SysConst.API_BASE_URL + '/auth/reset-password',
    PROSPECT_ACCOUNT_ENQ : SysConst.API_BASE_URL + '/v1/prospect/account/enquiry',
    USER_LOGGED_IN : SysConst.API_BASE_URL + '/v1/merchant/api/users/logged-in',
    USER_SEARCH : SysConst.API_BASE_URL + '/v1/merchant/api/users/search',
    USER_CREATE : SysConst.API_BASE_URL + '/v1/merchant/api/users',
    USER_UPDATE : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/users/${id}`,
    ACCOUNT_INFO : SysConst.API_BASE_URL + '/v1/merchant/api/users/account',
    USER_FIND_BY_ID : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/users/${id}`,
    USER_UPDATE_PASSWORD : SysConst.API_BASE_URL + '/v1/merchant/api/users/password/update',
    USER_RESET_CREDENTIAL_BY_ID : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/users/${id}/reset-credential`,
    RESET_PASSWORD : SysConst.API_BASE_URL + '/v1/merchant/api/public/password/reset',
    AUTH_ATTEMPT_EXCEED : SysConst.API_BASE_URL + '/v1/merchant/api/public/auth/attempt-exceed',
    CAPTCHA_TOKEN : SysConst.API_BASE_URL + '/v1/merchant/api/public/captcha/token',
    CAPTCHA_TOKEN_REFRESH : (captchaToken) => SysConst.API_BASE_URL + `/v1/merchant/api/public/captcha/token/${captchaToken}/refresh`,
    CAPTCHA_IMAGE : (captchaToken) => SysConst.API_BASE_URL + `/v1/merchant/api/public/captcha/${captchaToken}`,
    GROUP_SEARCH : SysConst.API_BASE_URL + '/v1/merchant/api/groups/search',
    GROUP_USERS : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/groups/${id}/users`,
    GROUP_ROLES : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/groups/${id}/roles`,
    GROUP_SAVE : SysConst.API_BASE_URL + '/v1/merchant/api/groups',
    GROUP_UPDATE : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/groups/${id}`,
    GROUP_DELETE : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/groups/${id}`,
    GROUP_FIND_BY_ID : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/groups/${id}`,
    ROLE_GET_ALL : SysConst.API_BASE_URL + '/v1/merchant/api/roles',
    ROLE_GET_ALL_DETAILS : SysConst.API_BASE_URL + '/v1/merchant/api/roles/details',
    MASTER_MERCHANT_FIND_ALL : SysConst.API_BASE_URL + '/v1/pay/api/payment/master-merchant/list',
    MASTER_MERCHANT_SEARCH : SysConst.API_BASE_URL + '/v1/pay/api/payment/master-merchant/search',
    MASTER_MERCHANT_INFO : SysConst.API_BASE_URL + '/v1/pay/api/payment/master-merchant/info',
    MASTER_MERCHANT_LOOKUP : SysConst.API_BASE_URL + '/v1/pay/api/payment/master-merchant/lookup',
    PAYMENT_MERCHANT_LOOKUP : SysConst.API_BASE_URL + '/v1/pay/api/payment/payment-merchant/lookup',
    OAUTH_CLIENT_FIND_ALL : SysConst.API_BASE_URL + '/v1/onepresto/api/onepresto/clients/get-all',
    ACCOUNT_OAUTH_CLIENT : SysConst.API_BASE_URL + '/v1/onepresto/api/onepresto/account/oauth-client',
    ACCOUNT_BINDING_LISTING : SysConst.API_BASE_URL + '/v1/onepresto/api/onepresto/account-binding/listing',
    PAYMENT_MERCHANT_LISTING : SysConst.API_BASE_URL + '/v1/pay/api/payment/payment-merchant/listing',
    PAYMENT_MERCHANT_DETAIL : SysConst.API_BASE_URL + '/v1/pay/api/payment/payment-merchant/detail',
    GENERAL_PARAMS : SysConst.API_BASE_URL + '/v1/merchant/api/general/params',

    // Loyalty
    LOYALTY_TYPE_LIST : SysConst.API_BASE_URL + '/v1/pay/api/loyalty/type/list',
    LOYALTY_TRANSACTION_ISSUANCE_LISTING : SysConst.API_BASE_URL + '/v1/pay/api/loyalty/transaction/issuance/listing',
    LOYALTY_TRANSACTION_LISTING : SysConst.API_BASE_URL + '/v1/pay/api/loyalty/transaction/listing',
    LOYALTY_LIST : SysConst.API_BASE_URL + '/v1/pay/int/prestoloyalty/list',

    // Payment
    PAYMENT_TRANSACTION_CONSTANTS : SysConst.API_BASE_URL + '/v1/pay/api/payment/transaction/constants',
    PAYMENT_TRANSACTION_LISTING : SysConst.API_BASE_URL + '/v1/pay/api/payment/transaction/listing',
    PAYMENT_INVOICE_LISTING : SysConst.API_BASE_URL + '/v1/pay/api/payment/invoice/listing',
    PAYMENT_INVOICE_DOWNLOAD : SysConst.API_BASE_URL + '/v1/pay/api/payment/invoice/download',

    // Audit
    AUDIT_TRAILS_CONSTANTS : SysConst.API_BASE_URL + '/v1/merchant/api/audit-trails/constants',
    AUDIT_TRAILS_LISTING : SysConst.API_BASE_URL + '/v1/merchant/api/audit-trails/listing',
    AUDIT_TRAILS_CREATE : SysConst.API_BASE_URL + '/v1/merchant/api/audit-trails/create',

    // Points Management
    POINTS_MANAGEMENT_LISTING : SysConst.API_BASE_URL + '/v1/merchant/api/points-management/search',
    POINTS_MANAGEMENT_CREATE : SysConst.API_BASE_URL + '/v1/merchant/api/points-management',
    POINTS_MANAGEMENT_GET_DETAIL : SysConst.API_BASE_URL + '/v1/merchant/api/points-management',
    POINTS_MANAGEMENT_APPROVE : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/points-management/${id}/approve`,
    POINTS_MANAGEMENT_REJECT : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/points-management/${id}/reject`,
    POINTS_MANAGEMENT_PROCESS : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/points-management/${id}/process`,
    POINTS_MANAGEMENT_DOWNLOAD_REQUEST_FILE : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/points-management/${id}/download/request`,
    POINTS_MANAGEMENT_DOWNLOAD_RESULT_FILE : (id) => SysConst.API_BASE_URL + `/v1/merchant/api/points-management/${id}/download/result`,
    POINTS_MANAGEMENT_DOWNLOAD_SAMPLE_FILE : SysConst.API_BASE_URL + `/v1/merchant/api/points-management/download/sample`,

    // Subwallet
    SUBWALLET_INVOICE_CONSTANT : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/invoice-listing/constant',
    SUBWALLET_INVOICE_LISTING : SysConst.API_BASE_URL + '/v1/pay/api/loyalty/subwallet/invoice/listing',
    SUBWALLET_INVOICE_DOWNLOAD : SysConst.API_BASE_URL + '/v1/pay/api/loyalty/subwallet/invoice/download',
    SUBWALLET_CONSTANT : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/constant',
    SUBWALLET_TRANSACTIONS_CONSTANT : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/transactions/constant',
    SUBWALLET_TRANSACTIONS_LISTING : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/transactions/listing',
    SUBWALLET_ACCOUNTS_CONSTANT : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/accounts/constant',
    SUBWALLET_ACCOUNTS_LISTING : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/accounts/listing',
    SUBWALLET_ACCOUNTS_DETAIL : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/accounts/detail',
    SUBWALLET_ACCOUNTS_HISTORY : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/accounts/history',
    SUBWALLET_ACCOUNTS_HISTORY_CONSTANT : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/accounts/history/constant',
    SUBWALLET_POINTS_EXPIRY_LISTING : SysConst.API_BASE_URL + '/v1/subwallet/api/merchant/points/expiry-listing',

    // Report
    REPORT_REQUEST_CREATE : SysConst.API_BASE_URL + '/v1/merchant/api/reports/create',
    REPORT_REQUEST_LISTING : SysConst.API_BASE_URL + '/v1/merchant/api/reports/listing',
    REPORT_REQUEST_FILE_DOWNLOAD : (reportRefNum) => SysConst.API_BASE_URL + `/v1/merchant/api/reports/download/${reportRefNum}`,

    //Theme Account
    THEME_ACCOUNT_LISTING : SysConst.API_BASE_URL + '/v1/deals/api/theme/account/list',
    THEME_MERCHANT_LISTING : SysConst.API_BASE_URL + '/v1/deals/api/theme/merchant/list',
    THEME_ACCOUNT_DETAIL : SysConst.API_BASE_URL + '/v1/deals/api/theme/account/sso/detail',
    THEME_ACCOUNT_MASTER_MERCHANT_DETAIL : SysConst.API_BASE_URL + '/v1/deals/api/theme/account/mastermerchant/detail',
    THEME_MERCHANT_DETAIL : SysConst.API_BASE_URL + '/v1/deals/api/theme/merchant/detail'
}

export default ApiAddress;
const SysConst = {
    API_BASE_URL: process.env.REACT_APP_BASE_URL === '/' ? '' : process.env.REACT_APP_BASE_URL,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID || 'portal-ui',
    CLIENT_SECRET: process.env.REACT_APP_SECRET || '5ca6d9a5-b923-4321-9244-5213cac4bec2',
    ACCESS_TOKEN_STORE_NAME: '_ACT_TOKEN',
    REFRESH_TOKEN_STORE_NAME: '_ACT_REFRESH_TOKEN',
    GRID_PAGE_SIZE: 50,
    GRID_PAGE_SIZES: [10,25,50,100],
    GRID_PAGE_BUTTON_COUNT: 5,
    DATE_FORMAT: 'dd/MM/yyyy',
    MONTH_FORMAT: 'MM/yyyy',
    DATE_TIME_FORMAT: 'dd/MM/yyyy HH:mm:ss',
    MAX_DATE_RANGE : 365,
    TRANSACTION_MAX_MONTH_RANGE : 3,
    DEFAULT_DATE_RANGE : 11,
    DEFAULT_DOWNLOAD_MAX_MONTH_RANGE : 12,
    PARTNER_TYPE_SSO_CLIENT : 'SSO Client',
    PARTNER_TYPE_PAYMENT_MASTER_MERCHANT : 'Payment Master Merchant'
};

export default SysConst;
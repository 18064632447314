import secureFetchJSON from "./secureFetchJSON";
import fetchJSON from './fetchJSON';

const defaultSuccessHandler = (callType, response) => console.log(`>>> ${callType} Success:`, response);
const defaultErrorHandler = (callType, response) => console.log(`>>> ${callType} Error:`, response);

const aRequest = (apiPath = '', opts = {}) => {
    const optsPublic = (opts.public === true);
    const aFetch = optsPublic ? fetchJSON : secureFetchJSON;
    return (
        aFetch(apiPath, {
            method: opts.method || 'POST',
            headers: opts.headers || {
                'Content-Type': 'application/json'
            },
            mode: opts.mode || 'cors',
            cache: opts.cache || 'no-cache',
            ...(opts.method !== 'GET'
                ? { body: opts.formUrlencoded === true? opts.data : JSON.stringify(opts.data) || JSON.stringify({}) }
                : {}
            )
        })
        .then(response => response.body)
    );
}

const http = {

    request: (apiPath = '', opts = {}) => {
        return (
            aRequest(apiPath, opts)
        );
    },

    get: (apiPath = '', opts = {}) => {
        return (
            aRequest(apiPath, {...opts, method: 'GET'})
        );
    },

    post: (apiPath = '', opts = {}) => {
        return (
            aRequest(apiPath, {...opts, method: 'POST'})
        );
    },

    put: (apiPath = '', opts = {}) => {
        return (
            aRequest(apiPath, {...opts, method: 'PUT'})
        );
    },

    delete: (apiPath = '', opts = {}) => {
        return (
            aRequest(apiPath, {...opts, method: 'DELETE'})
        );
    }
};

export {
    http,
    defaultSuccessHandler,
    defaultErrorHandler
}
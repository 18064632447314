import React, {Component} from "react";
import {connect} from "react-redux";
import {
    setSideBarCollapse,
    setActiveMenu,
    setUserInfo,
    clearUserInfo,
    showLoader,
    hideLoader,
    showDialog,
    hideDialog
} from '../../redux/actions';


function withRedux(WrappedComponent) {
    class MainWrapper extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = (state) => {
        return ({
            appState: state.app,
        })
    };

    const mapDispatchToProps = (dispatch) => ({
        setSideBarCollapse: (data) => dispatch(setSideBarCollapse(data)),
        setActiveMenu: (data) => dispatch(setActiveMenu(data)),
        setUserInfo: (data) => dispatch(setUserInfo(data)),
        clearUserInfo: () => dispatch(clearUserInfo()),
        showLoader: () => dispatch(showLoader()),
        hideLoader: () => dispatch(hideLoader()),
        showDialog: (data) => dispatch(showDialog(data)),
        hideDialog: (data) => dispatch(hideDialog(data)),
    });

    return connect(mapStateToProps, mapDispatchToProps)(MainWrapper);
}

export default withRedux;

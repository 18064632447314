import {http, defaultSuccessHandler, defaultErrorHandler} from "../http";
import ApiAddress from "../../constants/ApiAddress";

const subwalletService = {

    fetchInvoiceListingConstant: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchInvoiceListingConstant', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchInvoiceListingConstant', data);

        return (
            http.get(ApiAddress.SUBWALLET_INVOICE_CONSTANT)
                .then(body => body.length === 0 ? onError(body) : onSuccess(body))
                .catch(error => onError(error.body))
        );
    },

    fetchInvoiceListing: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchInvoiceListing', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchInvoiceListing', data);

        return (
            http.post(ApiAddress.SUBWALLET_INVOICE_LISTING, {
                data: {
                    subwalletTypeTypes: opts.subwalletTypeTypes || null,
                    subwalletType: opts.subwalletType || null,
                    createdDateFrom: opts.createdDateFrom || null,
                    createdDateTo: opts.createdDateTo || null,
                    startIndex: opts.startIndex || 0,
                    pageSize: opts.pageSize || 0,
                    sort: opts.sort || []
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

    downloadInvoice: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('downloadInvoice', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('downloadInvoice', data);

        return (
            http.post(ApiAddress.SUBWALLET_INVOICE_DOWNLOAD, {
                data: {
                    invoiceNum: opts.invoiceNum || null,
                    reportType: opts.reportType || null,
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

    fetchConstant: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchConstant', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchConstant', data);

        return (
            http.get(ApiAddress.SUBWALLET_CONSTANT)
                .then(body => body.length === 0 ? onError(body) : onSuccess(body))
                .catch(error => onError(error.body))
        );
    },

    fetchTransactionListingConstant: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchTransactionListingConstant', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchTransactionListingConstant', data);

        return (
            http.get(ApiAddress.SUBWALLET_TRANSACTIONS_CONSTANT)
                .then(body => body.length === 0 ? onError(body) : onSuccess(body))
                .catch(error => onError(error.body))
        );
    },

    fetchTransactionListing: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchTransactionListing', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchTransactionListing', data);

        return (
            http.post(ApiAddress.SUBWALLET_TRANSACTIONS_LISTING, {
                data: {
                    transactionRefNum: opts.transactionRefNum || null,
                    transactionAction: opts.transactionAction || null,
                    merchantTrxRefNum: opts.merchantTrxRefNum || null,
                    subwalletType: opts.subwalletType || null,
                    transactionDateFrom: opts.transactionDateFrom || null,
                    transactionDateTo: opts.transactionDateTo || null,
                    partnerRefNum: opts.partnerRefNum || null,
                    mobileCountryCode: opts.mobileCountryCode || null,
                    mobileNum: opts.mobileNum || null,
                    prospectId: opts.prospectId || null,
                    startIndex: opts.startIndex || 0,
                    pageSize: opts.pageSize || 0,
                    sort: opts.sort || []
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

    fetchAccountListingConstant: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchAccountListingConstant', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchAccountListingConstant', data);

        return (
            http.get(ApiAddress.SUBWALLET_ACCOUNTS_CONSTANT)
                .then(body => body.length === 0 ? onError(body) : onSuccess(body))
                .catch(error => onError(error.body))
        );
    },

    fetchAccountHistoryConstant: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchAccountHistoryConstant', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchAccountHistoryConstant', data);

        return (
            http.get(ApiAddress.SUBWALLET_ACCOUNTS_HISTORY_CONSTANT)
                .then(body => body.length === 0 ? onError(body) : onSuccess(body))
                .catch(error => onError(error.body))
        );
    },

    fetchAccountListing: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchAccountListing', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchAccountListing', data);

        return (
            http.post(ApiAddress.SUBWALLET_ACCOUNTS_LISTING, {
                data: {
                    partnerRefNum: opts.partnerRefNum || null,
                    mobileCountryCode: opts.mobileCountryCode || null,
                    mobileNum: opts.mobileNum || null,
                    email: opts.email || null,
                    subwalletType: opts.subwalletType || null,
                    walletStatus: opts.walletStatus || null,
                    createdDateFrom: opts.createdDateFrom || null,
                    createdDateTo: opts.createdDateTo || null,
                    startIndex: opts.startIndex || 0,
                    pageSize: opts.pageSize || 0,
                    sort: opts.sort || []
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

    fetchAccountDetail: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchAccountDetail', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchAccountDetail', data);

        return (
            http.post(ApiAddress.SUBWALLET_ACCOUNTS_DETAIL, {
                data: {
                    walletId: opts.walletId || null
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

    fetchAccountHistory: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchAccountHistory', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchAccountHistory', data);

        return (
            http.post(ApiAddress.SUBWALLET_ACCOUNTS_HISTORY, {
                data: {
                    walletId: opts.walletId || null,
                    transactionDateFrom: opts.transactionDateFrom || null,
                    transactionDateTo: opts.transactionDateTo || null,
                    transactionAction: opts.transactionAction || null,
                    expiryDateFrom: opts.expiryDateFrom || null,
                    expiryDateTo: opts.expiryDateTo || null,
                    mode: opts.mode || null,
                    merchantTrxRefNum: opts.merchantTrxRefNum || null,
                    startIndex: opts.startIndex || 0,
                    pageSize: opts.pageSize || 0,
                    sort: opts.sort || []
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

    fetchPointsExpiryListing: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('fetchPointsExpiryListing', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('fetchPointsExpiryListing', data);

        return (
            http.post(ApiAddress.SUBWALLET_POINTS_EXPIRY_LISTING, {
                data: {
                    subwalletType: opts.subwalletType || null,
                    partnerRefNum: opts.partnerRefNum || null,
                    mobileCountryCode: opts.mobileCountryCode || null,
                    mobileNum: opts.mobileNum || null,
                    email: opts.email || null,
                    expiringIn: opts.expiringIn || null,
                    expiryDateFrom: opts.expiryDateFrom || null,
                    expiryDateTo: opts.expiryDateTo || null,
                    startIndex: opts.startIndex || 0,
                    pageSize: opts.pageSize || 0,
                    sort: opts.sort || []
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

};

export default subwalletService;
import React from "react";

import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import SysConst from "../../constants/SysConst";

function PointsManagementApprovalGrid({ dataSource, dataState, onDataStateChange, onDownloadClick, onViewDetailsClick, isCreateRequestAllowed, onCreateRequestClick, masterMerchantList, paymentMerchantList, subwalletList }) {
    const _export = React.useRef(null);

    const CellLink = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <td role={"gridcell"}>
                <button onClick={() => onViewDetailsClick(props.dataItem)} className="btn-a">{value === null ? "" : props.dataItem[field].toString()}</button>
            </td>
        );
    };

    const MasterMerchantCell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        const data = masterMerchantList.filter(n => n.merchantRefNum === value);
        return (
            <td role={"gridcell"}>
                {data && data[0] ? data[0].merchantName : value}
            </td>
        );
    };

    const MerchantCell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        const data = paymentMerchantList.filter(n => n.merchantRefNum === value);
        return (
            <td role={"gridcell"}>
                {data && data[0] ? data[0].merchantName : value}
            </td>
        );
    };

    const SubwalletTypeCell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        const data = subwalletList.filter(n => n.value === value);
        return (
            <td role={"gridcell"}>
                {data && data[0] ? data[0].label : value}
            </td>
        );
    };

    return (
        <ExcelExport fileName="Points_Management_Approval.xlsx" ref={_export}>
            <Grid
                sortable={true}
                pageable={
                    {
                        pageSizes: SysConst.GRID_PAGE_SIZES,
                        buttonCount: SysConst.GRID_PAGE_BUTTON_COUNT
                    }
                }
                {...dataState}
                data={dataSource}
                onDataStateChange={onDataStateChange}
            >
                <GridToolbar>
                    <div style={{ flexGrow: 1 }}>
                    </div>
                    <div>
                        <button
                            title="Export to Excel"
                            className="k-button k-button-md k-rounded-md"
                            onClick={() => onDownloadClick(_export)}
                        >
                            <i><FontAwesomeIcon icon={faDownload} /></i>
                        </button>
                    </div>
                </GridToolbar>
                <GridColumn field="requestRefNum" cell={CellLink} title="Request Ref Number" />
                <GridColumn field="masterMerchantRefNum" cell={MasterMerchantCell} title="Master Merchant Name" />
                <GridColumn field="merchantRefNum" cell={MerchantCell} title="Merchant Name" />
                <GridColumn field="subwalletType" cell={SubwalletTypeCell} title="Subwallet Type" />
                <GridColumn field="requestStatusLabel" title="Request Status" />
                <GridColumn field="createdBy" title="Requested By" />
                <GridColumn field="createdDate" title="Requested Date" />
                <GridColumn field="approvedBy" title="Approved By" />
                <GridColumn field="approvalDate" title="Approved Date" />
            </Grid>
        </ExcelExport>
    )
}

export default PointsManagementApprovalGrid;
import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { retrieveSession } from "../../services/auth";
import withRedux from "../WithRedux";
import { Routes } from "../../routes";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { appState, setUserInfo } = restOfProps;
  const session =  retrieveSession();
  const isAuthenticated = session != null;
  const accessToken = isAuthenticated ? session?.access_token : null;
  const { userInfo } = appState;
  const resetPassword = userInfo && userInfo.passwordExpiredDate;
  useEffect(() => {
    if (isAuthenticated && !userInfo) {
      const decodedToken = jwt_decode(accessToken);
      setUserInfo({
        accountRefNum: decodedToken.preferred_username.toUpperCase(),
        email: decodedToken.email,
        name: decodedToken.given_name,
        grantedAccess: decodedToken.realm_access.roles,
        groups: decodedToken.groups,
        partners: decodedToken.partners,
        passwordExpiredDate: decodedToken.passwordExpiredDate,
      });
    }
  }, [isAuthenticated, accessToken, userInfo, setUserInfo]);

  const isAuthorized = (roleId) => {
    const {grantedAccess} = userInfo;
    return grantedAccess.includes(roleId);
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? 
          resetPassword ? <Redirect to={Routes.RESET_PASSWORD.path} /> : <Component {...props} isAuthorized={isAuthorized} /> : 
        <Redirect to={Routes.SIGNIN.path} />
      }
    />
  );
}

export default withRedux(ProtectedRoute);
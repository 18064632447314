import { SIDEBAR_COLLAPSE, SET_ACTIVE_MENU, SET_USER_INFO, CLEAR_USER_INFO, UPDATE_LOADER_STATE, UPDATE_DIALOG_STATE } from './actionType'

export const defaultAppState = {
    sideBarCollapse: false,
    activeMenuId: null,
    userInfo: null,
    isLoaderShown: false,
    dialogState: {
        isShown: false
    }
}

export const appStateReducer = (state = defaultAppState, action) => {
    switch (action.type) {
        case SIDEBAR_COLLAPSE:
            return {
                ...state,
                sideBarCollapse: action.data
            };
        case SET_ACTIVE_MENU:
            return {
                ...state,
                activeMenuId: action.data
            };
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.data
            };
        case CLEAR_USER_INFO:
            return {
                ...state,
                userInfo: null
            };
        case UPDATE_LOADER_STATE:
            return {
                ...state,
                isLoaderShown: action.data
            };
        case UPDATE_DIALOG_STATE:
            return {
                ...state,
                dialogState: action.data
            };
        default:
            return state;
    }
};
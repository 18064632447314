// Originally from https://github.com/vlki/refresh-fetch
// Update to support redirect log out when fail to refresh access token

import { Routes } from "../../routes"


function configureRefreshFetch(configuration) {
  const { refreshToken, shouldRefreshToken, fetch } = configuration

  let refreshingTokenPromise = null

  return (url, options) => {
    if (refreshingTokenPromise !== null) {
      return (
        refreshingTokenPromise
          .then(() => fetch(url, options))
          // Even if the refreshing fails, do the fetch so we reject with
          // error of that request
          .catch(() => fetch(url, options))
      )
    }

    return fetch(url, options).catch(error => {
      if (shouldRefreshToken(error)) {
        if (refreshingTokenPromise === null) {
          refreshingTokenPromise = new Promise((resolve, reject) => {
            refreshToken()
              .then(() => {
                refreshingTokenPromise = null
                resolve()
              })
              .catch(refreshTokenError => {
                refreshingTokenPromise = null
                reject(refreshTokenError)
              })
          })
        }

        return refreshingTokenPromise
          .catch(() => {
            if (error.response.status === 401) {
              window.location.href = Routes.SIGNOUT.path;
              return;
            }
            throw error
          })
          .then(() => fetch(url, options))
      } else {
        throw error
      }
    })
  }
}

export default configureRefreshFetch

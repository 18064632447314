import React from 'react';
import {
  BrowserRouter as Router
} from "react-router-dom";

import {Provider} from 'react-redux';

import {SwitchRoutes} from './routes';

import store from './redux/store';

function App() {
  return (
    <Provider store={store}>
      <Router basename="/">
        <SwitchRoutes />
      </Router>
    </Provider>
  );
}

export default App;

// Originally from https://github.com/vlki/refresh-fetch
// Update to allways try JSON.parse no matter response Content-Type

import merge from 'lodash/merge'

const fetchJSON = (url, options = {}) => {
  const fetchOptions = typeof (options.body) === 'undefined'
    ? options
    : merge(
      {
        headers: {
          'Content-Type': 'application/json'
        }
      },
      options
    )

  return fetch(url, fetchOptions)
    .then((response) => {
      return getResponseBody(response).then(body => ({
        response,
        body
      }))
    })
    .then(checkStatus)
}

const getResponseBody = (response) => {
  return response.clone().text().then(tryParseJSON);
}

const tryParseJSON = (json) => {
  if (!json) {
    return {}
  }

  try {
    return JSON.parse(json)
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`)
  }
}

function ResponseError(status, response, body) {
  this.name = 'ResponseError'
  this.status = status
  this.response = response
  this.body = body
}

const checkStatus = ({ response, body }) => {
  if (response.ok) {
    return { response, body }
  } else {
    throw new ResponseError(response.status, response, body)
  }
}

export default fetchJSON

import { http, defaultSuccessHandler, defaultErrorHandler } from "../http";
import ApiAddress from "../../constants/ApiAddress";
import SysConst from "../../constants/SysConst";

const retrieveSession = () => {

    if (localStorage.getItem(SysConst.ACCESS_TOKEN_STORE_NAME)) {
        return ({
            access_token: localStorage.getItem(SysConst.ACCESS_TOKEN_STORE_NAME), 
            refresh_token: localStorage.getItem(SysConst.REFRESH_TOKEN_STORE_NAME)
        });
    }
    
    return null;
}

const saveSession = (response) => {
    localStorage.setItem(SysConst.ACCESS_TOKEN_STORE_NAME, response.access_token);
    localStorage.setItem(SysConst.REFRESH_TOKEN_STORE_NAME, response.refresh_token);
}

const clearSession = () => {
    localStorage.removeItem(SysConst.ACCESS_TOKEN_STORE_NAME);
    localStorage.removeItem(SysConst.REFRESH_TOKEN_STORE_NAME);
}

const authService = {

    token: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('token', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('token', data);

        var data = new URLSearchParams();
        data.append('client_id', SysConst.CLIENT_ID);
        data.append('client_secret', SysConst.CLIENT_SECRET);
        data.append('grant_type', 'password');
        data.append('username', opts.username);
        data.append('password', opts.password);

        return(
            http.request(ApiAddress.AUTH_TOKEN, {
                public: true,
                formUrlencoded: true,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: data
            })
            .then(body => body.length === 0 ? onError(body) : onSuccess(body))
            .catch(error => onError(error.body))
        );
    },

    refreshToken: () => {
        const onSuccess = (response) => saveSession(response);
        const onError = (error) => {
            clearSession();
            throw error
        };

        const session = retrieveSession();

        var data = new URLSearchParams();
        data.append('client_id', SysConst.CLIENT_ID);
        data.append('client_secret', SysConst.CLIENT_SECRET);
        data.append('grant_type', 'refresh_token');
        data.append('refresh_token', session?.refresh_token);

        return(
            http.request(ApiAddress.AUTH_TOKEN_REFRESH, {
                public: true,
                formUrlencoded: true,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: data
            })
            .then(body => body.length === 0 ? onError(body) : onSuccess(body))
            .catch(error => onError(error.body))
        );
    },

    logout: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('logout', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('logout', data);

        const session = retrieveSession();

        var data = new URLSearchParams();
        data.append('client_id', SysConst.CLIENT_ID);
        data.append('client_secret', SysConst.CLIENT_SECRET);
        data.append('refresh_token', session?.refresh_token);

        return(
            http.request(ApiAddress.AUTH_LOGOUT, {
                formUrlencoded: true,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: data
            })
            .then(body => body.length === 0 ? onError(body) : onSuccess(body))
            .catch(error => onError(error.body))
        );
    },

    resetPassword: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('resetPassword', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('resetPassword', data);

        return (
            http.post(ApiAddress.RESET_PASSWORD, {
                public: true,
                data: {
                    reCaptchaToken: opts.reCaptchaToken,
                    email: opts.email,
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    },

    authAttemptExceed: (opts) => {
        const onSuccess = !!opts.onSuccess ? opts.onSuccess : data => defaultSuccessHandler('authAttemptExceed', data);
        const onError = !!opts.onError ? opts.onError : data => defaultErrorHandler('authAttemptExceed', data);

        return (
            http.post(ApiAddress.AUTH_ATTEMPT_EXCEED, {
                public: true,
                data: {
                    username: opts.username,
                }
            })
                .then(response => response.length === 0 ? onError(response) : onSuccess(response))
                .catch(error => onError(error.body))
        );
    }

};

export {
    authService,
    retrieveSession,
    saveSession,
    clearSession
};
import merge from 'lodash/merge'
import fetchJSON from "./fetchJSON";
import configureRefreshFetch from "./configureRefreshFetch";
import {retrieveSession, authService} from '../auth'

const retrieveToken = () => {
    const session = retrieveSession();
    return session?.access_token;
};

const fetchJSONWithToken = (url, options = {}) => {
  const token = retrieveToken();

  let optionsWithToken = options
  if (token != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  return fetchJSON(url, optionsWithToken)
}

const shouldRefreshToken = error => {
  return error.response.status === 401 && error.body.error_description === 'Invalid bearer token'
}

const refreshToken = () => {
  return authService.refreshToken();
}

const secureFetchJSON = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
})

export default secureFetchJSON;
import React from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import SVGSpinLoaderIcon from '../../assets/svgs/icon-spin-loader';

function LoaderWrapper({
    isShown = false,
}) {
    return (
        <>
            {isShown && (
                <Dialog className='k-dialog-loader'>
                    <div style={overlayStyle}>
                        <SVGSpinLoaderIcon />
                    </div>
                </Dialog>
            )}
        </>
    );
}

export default LoaderWrapper;

const overlayStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};